import React from "react";
import { motion } from "framer-motion";
import "../styles/Loader.scss";
import { TextAnimate } from "./Home/TextAnimate";

export default function Loader() {
    return (
        <div class="loader-container">
            <div class="icon">
                <motion.svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000.000000 2000.000000"
                    preserveAspectRatio="xMidYMid meet"
                    animate={{
                        y: -10,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "reverse",
                        delay: 1,
                    }}
                >
                    <g
                        transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                    >
                        <path
                            d="M13569 17288 c-161 -189 -180 -214 -272 -353 -124 -190 -221 -363
    -382 -686 l-144 -288 98 -272 c54 -150 102 -274 107 -276 5 -2 78 131 163 295
    291 560 396 721 707 1087 l42 50 -69 195 c-121 342 -131 370 -138 370 -4 0
    -54 -55 -112 -122z"
                        />
                        <path
                            d="M14147 16399 c-90 -47 -243 -216 -475 -524 -400 -530 -494 -757 -426
    -1027 38 -153 387 -1055 625 -1615 39 -91 39 -93 39 -221 0 -560 -333 -2374
    -570 -3103 -86 -267 -150 -389 -203 -389 -82 0 -385 344 -829 940 -829 1113
    -1842 2669 -2618 4020 -499 870 -697 1147 -799 1124 -156 -36 -512 -510 -797
    -1059 -120 -232 -137 -310 -91 -410 13 -27 73 -129 132 -225 162 -261 437
    -720 615 -1025 745 -1279 1648 -2656 2405 -3670 483 -646 819 -1026 1035
    -1172 372 -251 676 -86 1145 622 153 230 272 440 425 750 149 301 245 523 358
    825 74 198 108 303 205 635 48 163 94 288 121 328 18 25 29 74 50 208 15 96
    31 188 35 204 l8 30 11 -27 c28 -69 993 -2316 1476 -3437 295 -684 535 -1245
    533 -1247 -1 -2 -149 46 -327 106 -1019 342 -1956 536 -2950 612 -213 16 -842
    16 -1030 0 -490 -42 -808 -104 -1183 -229 -96 -32 -172 -52 -177 -47 -30 31
    -675 1039 -970 1514 -1306 2108 -2196 3825 -3077 5940 -202 486 -285 674 -375
    854 -118 236 -173 311 -221 298 -38 -10 -238 -220 -363 -382 -294 -381 -373
    -504 -586 -915 l-146 -280 9 -250 c5 -137 20 -682 34 -1210 14 -528 30 -1118
    36 -1312 6 -193 9 -357 7 -363 -2 -7 -174 246 -383 561 -1210 1829 -1449 2140
    -1646 2143 -53 1 -115 -35 -212 -124 -226 -207 -661 -842 -807 -1180 -118
    -271 -204 -640 -440 -1897 -253 -1342 -410 -2009 -646 -2739 -134 -414 -151
    -504 -97 -504 82 0 255 162 453 425 387 514 713 1281 955 2246 76 304 131 562
    220 1034 86 455 111 555 140 555 26 0 170 -172 346 -410 202 -273 459 -643
    953 -1369 738 -1084 1279 -1813 1441 -1943 43 -34 54 -39 85 -33 157 29 358
    294 653 860 63 121 170 319 237 440 l123 220 -66 510 c-67 509 -103 849 -132
    1235 -17 237 -20 460 -5 460 20 0 88 -71 138 -143 99 -144 277 -467 692 -1262
    684 -1308 1489 -2703 2142 -3711 36 -55 71 -111 77 -123 12 -21 5 -30 -97
    -126 -319 -304 -639 -738 -835 -1132 -119 -239 -144 -353 -79 -353 64 0 160
    60 412 258 249 194 519 366 790 502 126 63 281 133 285 128 1 -2 100 -149 219
    -327 120 -179 323 -477 452 -663 316 -458 1272 -1798 1283 -1798 11 0 289 341
    367 453 145 204 622 1036 608 1059 -4 7 -78 105 -164 218 -164 214 -364 497
    -715 1010 -115 168 -226 330 -248 360 l-38 55 198 3 c1016 16 2495 -305 3770
    -819 640 -258 1135 -512 1653 -849 231 -151 357 -225 474 -281 l85 -41 43 25
    c143 84 510 556 735 947 66 114 273 524 273 540 0 5 -204 431 -454 947 -727
    1504 -1047 2195 -1761 3803 -640 1442 -1069 2444 -1485 3475 -109 270 -293
    720 -410 1000 -394 946 -532 1302 -550 1414 -15 90 -49 109 -123 70z"
                        />
                        <path
                            d="M6784 8993 c-344 -661 -443 -822 -679 -1098 -74 -88 -135 -163 -135
    -168 0 -8 132 -483 151 -545 6 -18 15 -32 19 -30 5 2 74 86 155 186 237 298
    324 438 585 938 l168 321 -81 282 c-45 154 -85 281 -89 281 -4 0 -46 -75 -94
    -167z"
                        />
                    </g>
                </motion.svg>
            </div>
            <TextAnimate
                ownStyle={{ color: "white", fontSize: "1.1em" }}
                initial={{ y: "100%" }}
                animate="visible"
                variants={{
                    visible: (i) => ({
                        y: 0,
                        transition: {
                            delay: i * 0.1,
                        },
                    }),
                }}
            >
                Chargement...
            </TextAnimate>
        </div>
    );
}
